.input-area {
    color: #dddddd;
    font-size: 3vw;
    font-family: 'Roboto Mono', monospace;

    position: fixed;
    top: 60%;

    width: 100%;
    height: 100%;
    text-align: center;

    z-index: -1;
}

.input-area-field {
    width: 50vw;
    height: 5vw;
    line-height: 5vw;
    font-size: 3vw;
    border: none;
    color: #dddddd;
    font-family: 'Roboto Mono', monospace;
    background-color: #111111dd;

    padding-left: 2vw;
    margin-right: 5vw;
    margin-bottom: 3vw;
}

.input-area-field::placeholder {   
    color: #888888;
}

.input-area-button {
    padding: 0 2vw;
    height: 5vw;
    font-size: 3vw;
    background-color: #aaaaaa;
    border: none;
    font-family: 'Roboto Mono', monospace;
}

.input-area-info {
    font-weight: normal;
}