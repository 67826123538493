@font-face {
    font-family: 'digitalFont';
    src: url('../../public/fonts/ds_digital/DS-DIGII.TTF');
}

.countdown {
    font-family: 'digitalFont';
    color: red;
    text-shadow: 0 0 4vw red;

    position: fixed;
    top: 10%;

    width: 100%;
    height: 100%;
    text-align: center;
    font-size: 20vw;

    z-index: -1;
}
